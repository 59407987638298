import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { Link, Text } from '@primer/react';
import { Link as GatsbyLink } from 'gatsby';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Box = makeShortcode("Box");
const CustomVideoPlayer = makeShortcode("CustomVideoPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h2>{`Usage`}</h2>
    <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      alignItems: 'flex-start',
      gap: 3
    }} mdxType="Box">
      <div>
        <p>{`A spinner is an indeterminate loading indicator, meaning it's best for cases where the progress or duration of a process is variable or unknown.`}</p>
        <p>{`A spinner may be shown on it's own or paired with a loading message. Spinners may be used as a placeholder for loading content, or just as an indicator that some action is being processed.`}</p>
        <p>{`More detailed guidance about designing for loading states can be found in our `}<a parentName="p" {...{
            "href": "/ui-patterns/loading"
          }}>{`loading guidelines`}</a>{`.`}</p>
      </div>
      <CustomVideoPlayer src="https://github.com/primer/design/assets/2313998/50234598-852a-46fc-be07-72bb6f35691b" width="456" autoPlay loop mdxType="CustomVideoPlayer" />
    </Box>
    <h2>{`Options`}</h2>
    <Box display="flex" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      alignItems: 'flex-start',
      gap: 3
    }} mdxType="Box">
      <div>
        <h3>{`Size`}</h3>
        <p>{`The size of the spinner can be adjusted. The `}<strong parentName="p">{`medium`}</strong>{` (default) variant works best for most cases. The `}<strong parentName="p">{`small`}</strong>{` variant is optimized for being displayed inline with loading text. The `}<strong parentName="p">{`large`}</strong>{` variant works well for replacing entire regions or pages that are still loading.`}</p>
      </div>
      <img src="https://github.com/primer/design/assets/2313998/b31c4058-929f-471c-860e-6a8c2c6af21e" width="960" role="presentation" />
    </Box>
    <h2>{`Accessibility and usability expectations`}</h2>
    <p>{`Use the `}<inlineCode parentName="p">{`Spinner`}</inlineCode>{` component to provide a visible loading indicator for situations where an asynchronous API call or process may take a moderate amount of time (between 1 and 3 seconds) – see `}<a parentName="p" {...{
        "href": "https://primer.style/ui-patterns/loading#adapting-to-different-wait-times"
      }}>{`Adapting to different wait times`}</a>{`.`}</p>
    <h2>{`Built-in accessibility features`}</h2>
    <p>{`The component only provides a visual, animated spinner icon. This icon is not exposed to screen readers, as it's an `}<inlineCode parentName="p">{`<svg>`}</inlineCode>{` without a `}<inlineCode parentName="p">{`role`}</inlineCode>{`, `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{`, or any text content.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<svg height="32px" width="32px" viewBox="0 0 16 16" fill="none" class="Spinner__StyledSpinner…"> … </svg>
`}</code></pre>
    <p>{`The spinner icon uses the default `}<inlineCode parentName="p">{`currentColor`}</inlineCode>{` of its parent container. Unless authors specifically override the default colors, the icon meets minimum color contrast requirements on the canvas default white background.`}</p>
    <h2>{`Implementation requirements`}</h2>
    <p>{`Do not rely solely on the `}<inlineCode parentName="p">{`Spinner`}</inlineCode>{` component as a loading indicator, as it currently isn't exposed to screen readers, and has no built-in announcement.`}</p>
    <p>{`Only use it as part of a fully realised solution (including `}<inlineCode parentName="p">{`aria-live`}</inlineCode>{` notifications) for conveying loading/processing states. Refer to the `}<a parentName="p" {...{
        "href": "https://primer.style/ui-patterns/loading"
      }}>{`loading guidelines`}</a>{` for more details.`}</p>
    <h2>{`How to test the component`}</h2>
    <h3>{`Integration tests`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The component only provides a visual indicator, and passes color contrast. Additional solutions are required (such as the use of `}<inlineCode parentName="p">{`aria-live`}</inlineCode>{` notifications in our `}<a parentName="p" {...{
            "href": "https://primer.style/ui-patterns/loading"
          }}>{`loading guidelines`}</a>{`) to convey the loading state for screen reader users.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If you are using custom colors, verify that the spinner's "spinning" section meets the minimum color contrast requirement of `}<inlineCode parentName="p">{`3:1`}</inlineCode>{` against the background it's presented on`}</p>
      </li>
    </ul>
    <h3>{`Component tests`}</h3>
    <ul>
      <li parentName="ul">{`The component renders an `}<inlineCode parentName="li">{`<svg>`}</inlineCode>{` element without any text content (as otherwise this would be announced by screen readers)`}</li>
    </ul>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
    <AccessibilityLink label="Spinner" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/ui-patterns/loading"
        }}>{`Loading guidelines`}</a></li>
    </ul>
    {/* TODO: link to Skeleton loaders once they're merged */}

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      